import React from 'react'

const Contact = () => {
    return (
        <div>
            <section class="contact-page-sec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <i class="fas fa-map-marked"></i>
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>address</h2>
                                        <span>1/51, Boothagudi, Boothagudi Post, Madurai - 625 017.</span>
                                        <span>Tamilnadu, India</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <i class="fas fa-envelope"></i>
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>E-mail</h2>
                                        <span>sakyacharitabletrust@gmail.com</span>
                                        <span>www.sakyacharitabletrust.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-info">
                                <div class="contact-info-item">
                                    <div class="contact-info-icon">
                                        <i class="fas fa-clock"></i>
                                    </div>
                                    <div class="contact-info-text">
                                        <h2>office time</h2>
                                        <span>Mon - Thu  9:00 am - 4.00 pm</span>
                                        <span>Thu - Mon  10.00 pm - 5.00 pm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="contact-page-form">
                                <h2>Get in Touch</h2>
                                <form>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="text" placeholder="Your Name" name="name" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="email" placeholder="E-mail" name="email" required />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="text" placeholder="Phone Number" name="phone" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12">
                                            <div class="single-input-field">
                                                <input type="text" placeholder="Subject" name="subject" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 message-input">
                                            <div class="single-input-field">
                                                <textarea placeholder="Write Your Message" name="message"></textarea>
                                            </div>
                                        </div>
                                        <div class="single-input-fieldsbtn">
                                            <input type="submit" value="Send Now" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="contact-page-map">
                                {/* <iframe
                                    width="600"
                                    height="450"
                                    style={{ border: 0 }}
                                    loading="lazy"
                                    allowfullscreen
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3777.987407558813!2d98.9933669!3d18.7540995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTjCsDQ1JzE0LjciTiA5OMKwNTknNDQuMCJF!5e0!3m2!1sid!2s!4v1469346687955"
                                /> */}
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62883.09630750552!2d78.08771835090755!3d9.917835025361331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c582b1189633%3A0xdc955b7264f63933!2sMadurai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1675920282276!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Contact
