import React from 'react'
import Logo from '../assets/images/mainlogo.png'
import Insta from '../assets/images/instagram.svg'
import Twitter from '../assets/images/twitter.svg'
import Facebook from '../assets/images/facebook.svg'
import Mail from '../assets/images/envelope-solid.svg'

const Footer = () => {
    return (
        <div>
            <footer class="footer">
                <div class="footer__addr">
                    {/* <h1 class="footer__logo">Something</h1> */}
                    <img src={Logo} alt="" width="75px" />
                    <h2>Contact Us</h2>

                    <address>
                        1/51, Boothagudi, Boothagudi Post,
                        Madurai - 625 017, Tamilnadu, India<br />

                        {/* <a class="footer__btn" href="mailto:example@gmail.com">Email Us</a> */}
                    </address>
                </div>

                <ul class="footer__nav">
                    <li class="nav__item">
                        <h2 class="nav__title">Media</h2>

                        <ul class="nav__ul">
                            <li>
                                <a href="#">News & Events</a>
                            </li>

                            <li>
                                <a href="#">Newsletter</a>
                            </li>

                            <li>
                                <a href="#">Photo Gallery</a>
                            </li>

                            <li>
                                <a href="#">Publications</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav__item nav__item--extra">
                        <h2 class="nav__title">About Us</h2>

                        <ul class="nav__ul nav__ul--extra">
                            <li>
                                <a href="#">Our History</a>
                            </li>

                            <li>
                                <a href="#">Our Team</a>
                            </li>

                            <li>
                                <a href="#">Our Supporters - Corporate Supporters</a>
                            </li>

                            <li>
                                <a href="#">Legal Certificates</a>
                            </li>

                            <li>
                                <a href="#">Financials and Annual Reports</a>
                            </li>

                            <li>
                                <a href="#">Milestones</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav__item">
                        <h2 class="nav__title">Legal</h2>

                        <ul class="nav__ul">
                            <li>
                                <a href="#">Privacy Policy</a>
                            </li>

                            <li>
                                <a href="#">Terms of Use</a>
                            </li>

                            <li>
                                <a href="#">FAQs</a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div class="legal">
                    <p>&copy; 2023 Sakya Charitable Trust. All rights reserved.</p>
                    <div class="legal__links">
                        <img src={Insta} alt="" width="25px" style={{ cursor: "pointer" }} /> &nbsp;&nbsp;&nbsp;
                        <img src={Twitter} alt="" width="25px" style={{ cursor: "pointer" }} /> &nbsp;&nbsp;&nbsp;
                        <img src={Facebook} alt="" width="25px" style={{ cursor: "pointer" }} /> &nbsp;&nbsp;&nbsp;
                        <img src={Mail} alt="" width="25px" style={{ cursor: "pointer" }} /> &nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Footer
