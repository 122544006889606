import logo from './logo.svg'
import './App.css'
import './style.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { NavBar } from './Component/navBar'
import Footer from './Component/footer'
import Home from './Component/home'
import About from './Component/about'
import Blog from './Component/blog'
import WhatWeDo from './Component/whatWeDo'
import Contact from './Component/contact'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'

function App() {
	return (
		<>
			<BrowserRouter>
				<NavBar />

				{/* <div className='pages'> */}
				<Routes>
					<Route exact path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/blog' element={<Blog />} />
					<Route path='/whatwedo' element={<WhatWeDo />} />
					<Route path='/contact' element={<Contact />} />
				</Routes>
				{/* </div> */}
				<Footer />
			</BrowserRouter>
		</>
	)
}

export default App
