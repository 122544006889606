export const dataItems = [
	{
		_id: '63bd19657613955e726726b0',
		category_name: 'Art',
		isStatus: true,
		created_date: 1673337189074,
		__v: 0,
		collection_details: [
			{
				_id: '63bd1ae07613955e726726e1',
				category_name: 'Art',
				collection_about: 'Any kind of drawing',
				collection_twitterLink: 'https://twitter.com/greenheart',
				collection_externalLink: 'http://greenheart.com',
				collection_name: 'Drawing',
				collection_profile:
					'https://s3devteam6.s3.amazonaws.com/BarterEraNFT/nft-25.jpg',
				collection_banner:
					'https://s3devteam6.s3.amazonaws.com/BarterEraNFT/integration.jpg',
				isStatus: true,
				item_activity: [],
				created_date: 1673337568823,
				__v: 0,
				category_id: '63bd19657613955e726726b0'
			},
			{
				_id: '63c93d27c5d5b1e7c33787cf',
				category_name: 'Art',
				collection_about: 'Any kind of drawing',
				collection_twitterLink: 'https://twitter.com/greenheart',
				collection_externalLink: 'http://greenheart.com',
				collection_name: 'Color paint',
				collection_profile:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-22.jpg',
				collection_banner:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-19.jpg',
				isStatus: true,
				item_activity: [],
				created_date: 1674132775094,
				__v: 0,
				category_id: '63bd19657613955e726726b0'
			},
			{
				_id: '63cb76167542e14099a45051',
				category_name: 'Art',
				collection_about: 'The picturaization looks like 3D effect feel',
				collection_twitterLink: 'https://twitter.com/greenheart',
				collection_externalLink: 'http://greenheart.com',
				collection_name: 'Three-D Painting',
				collection_profile:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-16.jpg',
				collection_banner:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-15.jpg',
				isStatus: true,
				item_activity: [],
				created_date: 1674278422673,
				__v: 0,
				category_id: '63bd19657613955e726726b0'
			},
			{
				_id: '63cb76907542e14099a4505b',
				category_name: 'Art',
				collection_about: 'The drawing is made about full of water coloring',
				collection_twitterLink: 'https://twitter.com/greenheart',
				collection_externalLink: 'http://greenheart.com',
				collection_name: 'Water coloring',
				collection_profile:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-17.jpg',
				collection_banner:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-13.jpg',
				isStatus: true,
				item_activity: [],
				created_date: 1674278544435,
				__v: 0,
				category_id: '63bd19657613955e726726b0'
			},
			{
				_id: '63cb77587542e14099a45061',
				category_name: 'Art',
				collection_about: 'The art is made full of varieties of sands',
				collection_twitterLink: 'https://twitter.com/greenheart',
				collection_externalLink: 'http://greenheart.com',
				collection_name: 'Sand drawing',
				collection_profile:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-9.jpg',
				collection_banner:
					'https://s3devteam6.s3.amazonaws.com/greenheart/clc-bnr-2.jpg',
				isStatus: true,
				item_activity: [],
				created_date: 1674278744239,
				__v: 0,
				category_id: '63bd19657613955e726726b0'
			}
		]
	},
	{
		_id: '63bd19767613955e726726b4',
		category_name: 'Music',
		isStatus: true,
		created_date: 1673337206475,
		__v: 0,
		collection_details: []
	},
	{
		_id: '63bd19b97613955e726726c4',
		category_name: 'Video',
		isStatus: true,
		created_date: 1673337273886,
		__v: 0,
		collection_details: []
	},
	{
		_id: '63bd19d77613955e726726c8',
		category_name: 'Avatar',
		isStatus: true,
		created_date: 1673337303512,
		__v: 0,
		collection_details: []
	},
	{
		_id: '63bd19eb7613955e726726cc',
		category_name: 'Physical Products',
		isStatus: true,
		created_date: 1673337323105,
		__v: 0,
		modfied_date: 1673337333379,
		collection_details: [
			{
				_id: '63bfcfb85dab9501e5767e55',
				category_name: 'Physical Products',
				collection_about: 'All kind of productss available',
				collection_twitterLink: 'https://twitter.com/greenheart',
				collection_externalLink: 'http://greenheart.com',
				collection_name: 'Medicine',
				collection_profile:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-2.jpg',
				collection_banner:
					'https://s3devteam6.s3.amazonaws.com/greenheart/nft-7.jpg',
				isStatus: true,
				item_activity: [],
				created_date: 1673514936527,
				__v: 0,
				category_id: '63bd19eb7613955e726726cc'
			}
		]
	},
	{
		_id: '63cbb75be89799a6236e12de',
		category_name: 'avatar',
		isStatus: true,
		created_date: 1674295131631,
		__v: 0,
		collection_details: []
	}
]
