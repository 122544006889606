import React from 'react'

const about = () => {
    console.log("object");
    return (
        // <div class="container my-5">
        //     <div class="row">
        //         <div class="col-md-6 pt-3">
        //             <small class="text-uppercase" style={{ color: "#9B5DE5" }}>About us</small>
        //             <h1 class="h2 mb-4" style={{ fontWeight: 600 }}>About Our <span style={{ color: "#9B5DE5" }}>Company</span></h1>
        //             <p class="text-secondary" style={{ lineHeight: 2 }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit repellat iure laboriosam cum voluptatum, nam minima deserunt aut? Distinctio voluptatibus dolor quaerat quo omnis illo sequi at velit, odit quod!</p>
        //             <a href="#" style={{ backgroundColor: "#9B5DE5", width: "240px" }} class="btn px-4 py-3 mt-4 mb-3 text-white d-flex align-items-center justify-content-between">
        //                 <span>Become a Voulnteer</span>
        //             </a>
        //         </div>
        //         <div class="col-md-6 text-center">
        //             <div class="row">
        //                 <div class="about-img mb-3">
        //                     <img src="https://htmldemo.net/grant-foundation/grant-foundation/img/about/about-image.jpg" class="w-100 rounded" alt="" />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <>
            <div class="relative__container">
                <div class="full__width object__fit">
                    <img src="https://htmldemo.net/grant-foundation/grant-foundation/img/about/page-banner.jpg" alt="background picture" />
                    <div class="absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512">
                            <path d="M436.67 184.11a27.17 27.17 0 01-38.3 0l-22.48-22.49a27.15 27.15 0 010-38.29l50.89-50.89a.85.85 0 00-.26-1.38C393.68 57 351.09 64.15 324.05 91c-25.88 25.69-27.35 64.27-17.87 98a27 27 0 01-7.67 27.14l-173 160.76a40.76 40.76 0 1057.57 57.54l162.15-173.3a27 27 0 0126.77-7.7c33.46 8.94 71.49 7.26 97.07-17.94 27.49-27.08 33.42-74.94 20.1-102.33a.85.85 0 00-1.36-.22z" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="20" />
                            <path d="M224 284c-17.48-17-25.49-24.91-31-30.29a18.24 18.24 0 01-3.33-21.35 20.76 20.76 0 013.5-4.62l15.68-15.29a18.66 18.66 0 015.63-3.87 18.11 18.11 0 0120 3.62c5.45 5.29 15.43 15 33.41 32.52M317.07 291.3c40.95 38.1 90.62 83.27 110 99.41a13.46 13.46 0 01.94 19.92L394.63 444a14 14 0 01-20.29-.76c-16.53-19.18-61.09-67.11-99.27-107" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="20" />
                            <path d="M17.34 193.5l29.41-28.74a4.71 4.71 0 013.41-1.35 4.85 4.85 0 013.41 1.35h0a9.86 9.86 0 008.19 2.77c3.83-.42 7.92-1.6 10.57-4.12 6-5.8-.94-17.23 4.34-24.54a207 207 0 0119.78-22.6c6-5.88 29.84-28.32 69.9-44.45A107.31 107.31 0 01206.67 64c22.59 0 40 10 46.26 15.67a89.54 89.54 0 0110.28 11.64 78.92 78.92 0 00-9.21-2.77 68.82 68.82 0 00-20-1.26c-13.33 1.09-29.41 7.26-38 14-13.9 11-19.87 25.72-20.81 44.71-.68 14.12 2.72 22.1 36.1 55.49a6.6 6.6 0 01-.34 9.16l-18.22 18a6.88 6.88 0 01-9.54.09c-21.94-21.94-36.65-33.09-45-38.16s-15.07-6.5-18.3-6.85a30.85 30.85 0 00-18.27 3.87 11.39 11.39 0 00-2.64 2 14.14 14.14 0 00.42 20.08l1.71 1.6a4.63 4.63 0 010 6.64L71.73 246.6a4.71 4.71 0 01-3.41 1.4 4.86 4.86 0 01-3.41-1.35l-47.57-46.43a4.88 4.88 0 010-6.72z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="20" />
                        </svg>
                        <h1 className="main-banner-head">About Us</h1>
                        <p className="main-banner-text">-----------------</p>
                        {/* <a href="#" class="button">Find Local Dealers!</a> */}
                    </div>
                </div>
            </div>

            <section class="section_all bg-light" id="about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section_title_all text-center">
                                <h3 class="font-weight-bold">About Us</h3>
                            </div>
                        </div>
                    </div>

                    <div class="row vertical_content_manage mt-5">
                        <div class="col-lg-6">
                            <div class="about_header_main mt-3">
                                <div class="about_icon_box">
                                    <p class="text_custom font-weight-bold">We Jointly welcome you all to contribute for the society</p>
                                </div>
                                {/* <h4 class="about_heading text-capitalize font-weight-bold mt-4">Lorem Ipsum is simply dummy text of the printing industry.</h4> */}
                                <p class="text-muted mt-3">Through our expertise and dedication, we transform the plans of the world’s most successful individuals, families and institutions into action so that we can help our clients reach their most ambitious goals.</p>

                                <p class="text-muted mt-3"> Spanning decades and generations, our longevity comes from the recognition that we are more than a financial institution. We are active participants in our communities, global leaders in business and philanthropy and thoughtful architects of innovative employee benefit and support programs.</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="img_about mt-3">
                                <img src="https://htmldemo.net/grant-foundation/grant-foundation/img/about/about-image.jpg" alt="" class="img-fluid mx-auto d-block" />
                            </div>
                        </div>
                    </div>                    
                </div>

            </section>
        </>
    )
}

export default about;
