import React from 'react'
import mission from '../assets/images/mission.png'
import vision from '../assets/images/vision.png'
import idea from '../assets/images/lightbulb.png'
import a1 from '../assets/images/a1.png'
import draw from '../assets/images/drawing.png'
import parai from '../assets/images/parai.png'
import tree from '../assets/images/tree.png'
import aware from '../assets/images/aware.png'
import { Container, Row, Col, Card, Media } from "reactstrap"
import { dataItems } from '../file'
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

AOS.init({
    duration: 800
});
const Home = () => {

    console.log(dataItems, "dataItems");

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
    };

    return (
        <div className="vision">

            {/* <Slider {...settings}>
                {dataItems.map((item, index) => {
                    return (
                        <div class="card">
                            <div class="card-header">
                                <img src="https://c0.wallpaperflare.com/preview/483/210/436/car-green-4x4-jeep.jpg" alt="rover" />
                            </div>
                            <div class="card-body">
                                <span class="tag tag-teal">{item.category_name}</span>
                                <h4>
                                    Why is the Tesla Cybertruck designed the way it
                                    is?
                                </h4>
                                <p>
                                    An exploration into the truck's polarising design
                                </p>
                                <div class="user">
                                    <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
                                    <div class="user-info">
                                        <h5>July Dec</h5>
                                        <small>2h ago</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider> */}

            <section>
                <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-pause="hover">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="1500">
                            <img
                                src="https://www.smilefoundationindia.org/wp-content/uploads/2022/11/education-scaled.jpg"
                                class="d-block w-100"
                                alt="pic-1"
                            />
                            <div class="container">
                                <div class="carousel-caption d-none d-md-block text-left">
                                    <h1 class="animated fadeInDown">Education is Empowerment</h1>
                                    {/* <p class="animated zoomInLeft">Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                                    <p><a class="btn btn-lg btn-primary" href="#" role="button">Sign up today</a></p> */}
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item" data-bs-interval="1500">
                            <img
                                src="https://images.unsplash.com/photo-1623069293749-c1963b6d5209?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1189&q=80"
                                class="d-block w-100"
                                alt="pic-1"
                            />
                            <div class="container">
                                <div class="carousel-caption d-none d-md-block">
                                    <h1 class="animated fadeInDown">You must trust and believe in people, or life becomes impossible.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </section>

            <div>
                <div data-aos="fade-up">
                    <h1 className="heading-title">SAKYA CHARITABLE TRUST- a Critique</h1>

                    <p className="explanation text-center">
                        Sakya Charitable Trust was started in May 2019 as a Non-Governmental Organization.
                        The Word <b>“SAKYA”</b> is taken from Buddhism which means one who possesses Energy and
                        Spirit forever. This Organization is entrusted to do Public Awareness, Educational and
                        Vocational Training, Free Legal Advice, and Social Service among the needy and poor people
                        regardless of Caste, Religion, Language, Colour, Sex, and any other discriminating factors.
                        <br />
                        <br />

                        <b>We had registered our Trust under Indian Trust act of 1882.</b>
                        <br />
                        <br />
                        From the beginning of the trust, we
                        had been focusing on the people who were beyond the shared sense of identity, especially on the
                        Folk Artists, Tribes, Women & Transgenders. We have been performing social services with the
                        help of volunteers around Tamil Nadu. We focus on Unequal Societies which have higher levels
                        of inequality and poverty. We aim to create a society that does not fight for their right to life and
                        security, Right to Education, and Right to Live. We work on the ground to provide the
                        unemployed Folk artists a job for their lives.</p>
                </div>

            </div>


            {/* <div class="container img-packs img-packs2">
                <div class="row wrapper wrapper2 center-block">
                    <div class="tile col-xs-12 col-sm-4">
                        <div class="caption2">
                            <img alt="china oldest tea trees make best tea" class="img-responsive1 center-block" src={idea} /> <br />
                            <span class="title"><b>Idealogy</b></span> <br /><br />
                            <p class="details">Our Ideology is Humanism. The wordmark of our trust is Morality, a progressed code of
                                conduct put forward by society to govern a person’s behaviour. To teach that morals as a social
                                awakening to the society, Education is used, and to ensure the persistence of traditional culture
                                among the forthcoming generations we here use the Art as a tenet to transform the society with
                                our in fatigable charity. So, we had named our trust “SAKYA CHARITABLE TRUST”.</p>
                        </div>
                    </div>
                    <div class="tile col-xs-12 col-sm-4 ">
                        <div class="caption2">
                            <img alt="experienced and best craftsmanship chinese tea" class="img-responsive2 center-block" src={vision} /> <br />
                            <span class="title"><b>Vision</b></span> <br /> <br />
                            <p class="details">We seek to build a trend in which the benefits are focused on Folk artists, women, and
                                children who are true to attain the right to live in dignity and security. Also, they were taught to
                                compliance them with the modern world without affecting their legacy.</p>
                        </div>
                    </div>
                    <div class="tile col-xs-12 col-sm-4">
                        <div class="caption2">
                            <img alt="china oldest tea trees make best tea" class="img-responsive3 center-block" src={mission} /> <br />
                            <span class="title"><b>Mission</b></span> <br /><br />
                            <p class="details">To work for the development of the institution where common people with
                                traditional values of India. We contribute to and create Champions in the field of education and
                                cultural activities.</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div data-aos="fade-down">
                <h1 className="heading-title">SAKYA CHARITABLE TRUST- Features</h1> <br />
            </div>
            <section id="timeline">
                <article>
                    <div class="inner">
                        <span class="date">
                            <img src={idea} alt="" className="timeline-img" />
                        </span>
                        <div data-aos="fade-right">
                            <h2>Ideology</h2>
                            <p>Our Ideology is Humanism. The wordmark of our trust is Morality, a progressed code of
                                conduct put forward by society to govern a person’s behaviour. To teach that morals as a social
                                awakening to the society, Education is used, and to ensure the persistence of traditional culture
                                among the forthcoming generations we here use the Art as a tenet to transform the society with
                                our in fatigable charity. So, we had named our trust “SAKYA CHARITABLE TRUST”.</p>
                        </div>
                    </div>
                </article>
                <article>
                    <div class="inner">
                        <span class="date">
                            <img src={vision} alt="" className="timeline-img" />
                        </span>
                        <div data-aos="fade-left">
                            <h2>Vision</h2>
                            <p>We seek to build a trend in which the benefits are focused on Folk artists, women, and
                                children who are true to attain the right to live in dignity and security. Also, they were taught to
                                compliance them with the modern world without affecting their legacy.</p>
                        </div>
                    </div>
                </article>
                <article>
                    <div class="inner">
                        <span class="date">
                            <img src={mission} alt="" className="timeline-img" />
                        </span>
                        <div data-aos="fade-right">
                            <h2>Mission</h2>
                            <p>To work for the development of the institution where common people with
                                traditional values of India. We contribute to and create Champions in the field of education and
                                cultural activities.</p>
                        </div>
                    </div>
                </article>
            </section>

            <div class="main" id="signup">
                <div class="main__container">
                    <div class="main__content" data-aos="fade-right">
                        <h1>HOW CAN YOU HELP?</h1>
                        {/* <h2>Sign Up Today</h2> */}
                        <p>participatory project where you can sponsor the education of an underprivileged girl child for a minimum period of one year. For as little as INR 5400, you can support a girl studying in primary school (Class 1 to 5), and for INR 6000, you can support a Nanhi Kali studying in secondary school (Class 6 to 10) for an entire year. As a sponsor, you will receive the photograph and profile of your Nanhi Kali, followed by regular progress reports.</p>
                        <button class="main__btn"><a href="">Donate Now</a></button>
                    </div>
                    <div class="main__img__container">
                        <div class="main__img__card" id="card-2" data-aos="fade-left"><img src={a1} /></div>
                    </div>
                </div>
            </div>

            {/* card */}
            <div id="cards_landscape_wrap-2">
                <div class="container">
                    <div data-aos="fade-up">

                        <h1 className="heading-title">SAKYA CHARITABLE TRUST- ACTIVITIES</h1>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <a href="">
                                <div class="card-flyer">
                                    <div class="text-box">
                                        <div class="image-box">
                                            <img src={tree} alt="" />
                                        </div>
                                        <div class="text-container">
                                            <h6>TREE CAMPAIGN</h6>
                                            <p>Our trust has a Personal relationship with the Trees. Irrespective of any type of camp we conducts there will sure a tree sapling program, here we conducts a fully dedicated Tree Sapling
                                                Campaign at Government middle school, Vavidamaruthur Village of
                                                Madurai west block. Around 100 Tree packet were given to the school
                                                students.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <a href="">
                                <div class="card-flyer">
                                    <div class="text-box">
                                        <div class="image-box">
                                            <img src={aware} alt="" />
                                        </div>
                                        <div class="text-container">
                                            <h6>AWARENESS SONGS</h6>
                                            <p>The Best way to reach the People is by Awareness Programs. By having in knowledge, we had taken many solution to problems in the society through songs that too with folk songs. Notable among them are Helmet Song 1 and Helmet Song 2 which tells about the rules to be followed by the public while using the vehicles.</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <a href="">
                                <div class="card-flyer">
                                    <div class="text-box">
                                        <div class="image-box">
                                            <img src={parai} alt="" />
                                        </div>

                                        <div class="text-container">
                                            <h6>PARAI COMPETITION</h6>
                                            <p>Our trust arranged an awareness camp, To Enhance the ability of the young persons in parai music
                                                we had conducted a Online Parai Competition during the peak
                                                covid- 19 pandemic. In Association with Indira School of
                                                Performing Arts of Germany the winners were given a cheque amount <br /><br /></p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <a href="">
                                <div class="card-flyer">
                                    <div class="text-box">
                                        <div class="image-box">
                                            <img src={draw} alt="" />
                                        </div>
                                        <div class="text-container">
                                            <h6>DRAWING COURSES</h6>
                                            <p>Drawing Classes are being conducted in both online and off-line mode. Focus is given on rural children where availability of such extra curricular activities is less.The ofine classes were being conducted at the villages itself. More than 70 students were completed their course in this academic year.<br /><br /></p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home