import React from 'react'

const WhatWeDo = () => {
    return (
        <>
            <div class="relative__container">
                <div class="full__width object__fit">
                    <img src="https://htmldemo.net/grant-foundation/grant-foundation/img/about/page-banner.jpg" alt="background picture" />
                    <div class="absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512">
                            <path d="M436.67 184.11a27.17 27.17 0 01-38.3 0l-22.48-22.49a27.15 27.15 0 010-38.29l50.89-50.89a.85.85 0 00-.26-1.38C393.68 57 351.09 64.15 324.05 91c-25.88 25.69-27.35 64.27-17.87 98a27 27 0 01-7.67 27.14l-173 160.76a40.76 40.76 0 1057.57 57.54l162.15-173.3a27 27 0 0126.77-7.7c33.46 8.94 71.49 7.26 97.07-17.94 27.49-27.08 33.42-74.94 20.1-102.33a.85.85 0 00-1.36-.22z" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="20" />
                            <path d="M224 284c-17.48-17-25.49-24.91-31-30.29a18.24 18.24 0 01-3.33-21.35 20.76 20.76 0 013.5-4.62l15.68-15.29a18.66 18.66 0 015.63-3.87 18.11 18.11 0 0120 3.62c5.45 5.29 15.43 15 33.41 32.52M317.07 291.3c40.95 38.1 90.62 83.27 110 99.41a13.46 13.46 0 01.94 19.92L394.63 444a14 14 0 01-20.29-.76c-16.53-19.18-61.09-67.11-99.27-107" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="20" />
                            <path d="M17.34 193.5l29.41-28.74a4.71 4.71 0 013.41-1.35 4.85 4.85 0 013.41 1.35h0a9.86 9.86 0 008.19 2.77c3.83-.42 7.92-1.6 10.57-4.12 6-5.8-.94-17.23 4.34-24.54a207 207 0 0119.78-22.6c6-5.88 29.84-28.32 69.9-44.45A107.31 107.31 0 01206.67 64c22.59 0 40 10 46.26 15.67a89.54 89.54 0 0110.28 11.64 78.92 78.92 0 00-9.21-2.77 68.82 68.82 0 00-20-1.26c-13.33 1.09-29.41 7.26-38 14-13.9 11-19.87 25.72-20.81 44.71-.68 14.12 2.72 22.1 36.1 55.49a6.6 6.6 0 01-.34 9.16l-18.22 18a6.88 6.88 0 01-9.54.09c-21.94-21.94-36.65-33.09-45-38.16s-15.07-6.5-18.3-6.85a30.85 30.85 0 00-18.27 3.87 11.39 11.39 0 00-2.64 2 14.14 14.14 0 00.42 20.08l1.71 1.6a4.63 4.63 0 010 6.64L71.73 246.6a4.71 4.71 0 01-3.41 1.4 4.86 4.86 0 01-3.41-1.35l-47.57-46.43a4.88 4.88 0 010-6.72z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="20" />
                        </svg>
                        <h1 className="main-banner-head">What We Do ?</h1>
                        <p className="main-banner-text">-----------------</p>
                        {/* <a href="#" class="button">Find Local Dealers!</a> */}
                    </div>
                </div>
            </div>
            <div class="container">
                <div>
                    <div class="row vertical_content_manage mt-5">
                        <div class="col-lg-12">
                            <div class="section_title_all text-center">
                                <h3 class="font-weight-bold">How Can We Help?</h3>
                                {/* <p class="section_subtitle mx-auto text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry. <br />Lorem Ipsum has been the industry's standard dummy text.</p> */}
                                <div class="">
                                    <i class=""></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-4">
                            <div class="about_content_box_all mt-3">
                                <div class="about_detail text-center">
                                    <div class="about_icon">
                                        {/* <i class="fa fa-user"></i> */}
                                        <i class="fas fa-book-open"></i>
                                    </div>
                                    <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Charity for Education</h5>
                                    <p class="edu_desc mt-3 mb-0 text-muted">Supporting for education is not only for an individual, we are heading towards a bright future of a generation of kids. It’s the basic and every child’s right to have proper education.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="about_content_box_all mt-3">
                                <div class="about_detail text-center">
                                    <div class="about_icon">
                                        <i class="	fas fa-plus-square"></i>
                                    </div>
                                    <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Charity for Health</h5>
                                    <p class="edu_desc mb-0 mt-3 text-muted">There are lot many underprivileged who are suffering from various health issues and constantly ending up their lives without proper medication and support. </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="about_content_box_all mt-3">
                                <div class="about_detail text-center">
                                    <div class="about_icon">
                                        <i class="fas fa-paper-plane"></i>
                                    </div>
                                    <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Social Activities</h5>
                                    <p class="edu_desc mb-0 mt-3 text-muted">No one has ever become poor by giving their time and efforts for something worthy. We believe there is nothing more beautiful than someone who goes out of their way . </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default WhatWeDo
